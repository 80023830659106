const initTalkDeskWidget = () => {
  const touchId = process.env.REACT_APP_TALK_DESK_TOUCH_POINT_ID;

  let webchat;
  ((window, document, node, props, configs) => {
    if (window.TalkdeskChatSDK) {
      console.error('TalkdeskChatSDK already included');
      return;
    }
    const divContainer = document.createElement('div');
    divContainer.id = node;
    document.body.appendChild(divContainer);
    const src = 'https://talkdeskchatsdk.talkdeskapp.com/talkdeskchatsdk.js';
    const script = document.createElement('script');
    const firstScriptTag = document.getElementsByTagName('script')[0];
    script.type = 'text/javascript';
    script.charset = 'UTF-8';
    script.id = 'tdwebchatscript';
    script.src = src;
    script.async = true;
    firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
    script.onload = () => {
      // eslint-disable-next-line no-undef
      webchat = TalkdeskChatSDK(node, props);
      webchat.init(configs);
    };
  })(
    window,
    document,
    'tdWebchat',
    {
      touchpointId: touchId,
      accountId: '',
      region: 'td-us-1',
    },
    {
      enableValidation: false,
      enableEmoji: true,
      enableUserInput: true,
      enableAttachments: true,
    },
  );
};
export default initTalkDeskWidget;
